.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  background-color: #000 !important;
}

.swap {
  display: table;
  margin: 0 auto;
  border: 3px solid black;
  margin: auto !important; 
}
.card{
  
     background-color: #FEAC5E;
    max-width: 400px;
    border-radius: 23px;
    margin: 5px 0;
    padding: 12px 24px;
    color: #000;
}
.form-group {
    position: relative;
}



.form-label {
    position: absolute;
    top: 0.75rem;
    left: 20px;
    font-size: 12px;
    font-weight: 700;
}
.form-input {
    width: 95%;
    padding: 33px 7px 8px;
    font-size: 26px;
    font-weight: 700;
    text-align: right;
    border-radius: 0.25rem;
    border: 2px solid #000;
}
.form-btn {
    border-radius: 0px;
    background-color: #000 !important;
    border: 1px solid #000;
    color: #eee;
    width: 100%;
    font-size: 20px;
    padding: 13px;
    cursor: pointer;
    font-weight: 700;
    height: unset;
    line-height: unset;
}
.navbar {
  display: flex;
  justify-content: center;
  padding: 1.25rem 0;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #db6934; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert {
  padding: 20px;
  background-color: #04AA6D;
  color: white;
  opacity: 0.83;
  transition: opacity 0.6s;
  margin-bottom: 15px;
}